// routes
import { PATH_REPORT } from '../../../../../../routes/VI/MELCONT/paths';
// components
import Iconify from '../../../../../../components/Iconify';
import SvgIconStyle from '../../../../../../components/SvgIconStyle';
//
import { PATH_TRAINING } from '../../../../../../routes/generalPaths';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  form: getIcon('ic_form'),
  upload: getIcon('ic_upload'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  maintenance: getIcon('ic_maintenance'),
  search: getIcon('ic_search'),
  export: getIcon('ic_analytics_export'),
};

export const navConfig = [
  {
    group: 'Indicadores',
    items: [
      {
        title: 'Bases',
        path: PATH_REPORT.bases,
        icon: ICONS.analytics,
      },
    ],
  },
  {
    subheader: 'Esforço',
    group: 'Indicadores',
    icon: ICONS.analytics,
    items: [
      {
        title: 'Acompanhamento',
        path: PATH_REPORT.esforco.crmLeader,
        icon: ICONS.analytics,
      },
    ],
  },
  {
    subheader: 'Treinamentos',
    icon: <Iconify icon={'healthicons:i-training-class'} />,
    group: 'Ferramentas',
    items: [
      {
        title: 'Treinamentos',
        path: PATH_TRAINING.list
      },
      {
        title: 'Turmas',
        path: PATH_TRAINING.classes
      },
      {
        title: 'Officers',
        path: PATH_TRAINING.officer
      }
    ],
  }, 
];
