import { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Card, CardActionArea, Divider, Popover, Stack, Tooltip, Typography, alpha, useTheme } from "@mui/material";
// components
import Iconify from "../../../components/Iconify";
import Scrollbar from "../../../components/Scrollbar";
import { IconButtonAnimate } from '../../../components/animate';

export default function People() {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Tooltip title="Pessoas">
        <IconButtonAnimate
          ref={anchorRef}
          onClick={() => setOpen(!open)}
          color={open ? 'primary' : 'default'}
          sx={{
            padding: 0,
            margin: 0,
            width: 36,
            height: 36,
            ...(open && { bgcolor: alpha(theme.palette.grey[500], 0.5) })
          }}
        >
          <Iconify icon="ion:people" sx={{ fontSize: 27 }} />
        </IconButtonAnimate>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Scrollbar sx={{ width: 300, maxHeight: '60vh' }}>
          <Card sx={{ p: 1, width: 300, bgcolor: alpha(theme.palette.grey[500], 0.1) }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: 1 }}>
              <Typography variant="h6">
                Pessoas
              </Typography>
            </Stack>

            <Stack direction='column'>
              <Box>
                <Card
                  sx={{
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: alpha(theme.palette.grey[300], 0.1)
                    }
                  }}
                >
                  <CardActionArea sx={{ p: 1 }}
                    onClick={() => {
                      setOpen(false);
                      navigate('/files/list');
                    }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Iconify icon='entypo:archive' sx={{ fontSize: 24 }} />
                      <Typography>
                        Central de Arquivos
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
                <Divider sx={{ borderColor: theme.palette.grey[400], marginX: 0.5 }} />
              </Box>

              <Box>
                <Card
                  sx={{
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: alpha(theme.palette.grey[300], 0.1)
                    }
                  }}
                >
                  <CardActionArea sx={{ p: 1 }}
                    onClick={() => {
                      setOpen(false);
                      navigate('/orgchart');
                    }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Iconify icon='fluent:organization-20-filled' sx={{ fontSize: 24 }} />
                      <Typography>
                        Organograma
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
                <Divider sx={{ borderColor: theme.palette.grey[400], marginX: 0.5 }} />
              </Box>

              <Box>
                <Card
                  sx={{
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: alpha(theme.palette.grey[300], 0.1)
                    }
                  }}
                >
                  <CardActionArea sx={{ p: 1 }}
                    onClick={() => {
                      setOpen(false);
                      window.open('https://full.mindsight.com.br/valorinvestimentos/home', '_blank');
                    }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Iconify icon='uis:analytics' sx={{ fontSize: 24 }} />
                      <Typography>
                        Mindsight Full Analytics
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
                <Divider sx={{ borderColor: theme.palette.grey[400], marginX: 0.5 }} />
              </Box>

              <Box>
                <Card
                  sx={{
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: alpha(theme.palette.grey[300], 0.1)
                    }
                  }}
                >
                  <CardActionArea sx={{ p: 1 }}
                    onClick={() => {
                      setOpen(false);
                      window.open('https://performance.mindsight.com.br/valorinvestimentos/evaluation_rounds', '_blank');
                    }}
                  >
                    <Stack direction='row' spacing={1.5} alignItems='center'>
                      <Iconify icon='octicon:graph-16' sx={{ fontSize: 24 }} />
                      <Typography>
                        Mindsight - Avaliação de Desempenho
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
                <Divider sx={{ borderColor: theme.palette.grey[400], marginX: 0.5 }} />
              </Box>
            </Stack>
          </Card>
        </Scrollbar>
      </Popover>
    </>

  )
}