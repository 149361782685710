// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_IN = '/in';
const ROOTS_OKR = '/okr';
const ROOTS_TOOLS = '/tools';
const ROOTS_EXPORT = '/export';
const ROOTS_REQUESTS = '/requests';
const ROOTS_PRODUCTS = '/products';
const ROOTS_SETTINGS = '/settings'
const ROOTS_S_RANKING = '/superranking';
const ROOTS_S_RANKING_LEADER = '/superranking/leader';
const ROOTS_REPORT = '/report';
const ROOTS_NPS = '/nps';
const ROOTS_CAMPAIGNS = '/campaigns';
const ROOTS_GU = '/gu';
const ROOTS_PIPE = '/pipe';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ANNOUNCEMENTS = '/announcements';
const ROOTS_OFF_SHORE = '/offshore';
const ROOTS_BACKOFFICE = '/backoffice';
const ROOTS_RKYC = '/rkyc';
const ROOTS_DOCUMENTS = '/files';
const ROOTS_EVENTS = '/events';
const ROOTS_VCS = '/vcs';
const ROOTS_STORE = '/store';
const ROOTS_CLUB = '/club';
const ROOTS_TI = '/ti';
const ROOTS_TRAINING = '/training';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  update: '/update', 
  maintenance: '/maintenance',
};


export const PATH_IN = {
  root: ROOTS_IN,
  account: path(ROOTS_IN, '/account'),  
  welcome: path(ROOTS_IN, '/welcome'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  base: path(ROOTS_DASHBOARD, '/base'),
  campaigns: path(ROOTS_DASHBOARD, '/campaigns'),
};

export const PATH_S_RANKING = {
  root: ROOTS_S_RANKING,
  general: path(ROOTS_S_RANKING, '/general'),
  individual: path(ROOTS_S_RANKING, '/individual')
};

export const PATH_OFF_SHORE = {
  root: ROOTS_OFF_SHORE,
  gerencial: path(ROOTS_OFF_SHORE, '/gerencial'),
  gerencialAAI: path(ROOTS_OFF_SHORE, '/gerencialaai'),
  working: path(ROOTS_OFF_SHORE, '/working'),
}

export const PATH_ANNOUNCEMENTS = {
  root: ROOTS_ANNOUNCEMENTS,
  new: path(ROOTS_ANNOUNCEMENTS, '/new'),
  announcements: path(ROOTS_ANNOUNCEMENTS, '/all'),
  announcement: path(ROOTS_ANNOUNCEMENTS, '/announcement'),
  management: path(ROOTS_ANNOUNCEMENTS, '/management')
}

export const PATH_STORE = {
  root: ROOTS_STORE,
  catalog: path(ROOTS_STORE, '/catalog'),
  product: path(ROOTS_STORE, '/product'),
  cart: path(ROOTS_STORE, '/cart'),
  list: path(ROOTS_STORE, '/list'),
  myorders: path(ROOTS_STORE, '/myorders'),
  new: path(ROOTS_STORE, '/new'),
  control: path(ROOTS_STORE, '/control')
}

export const PATH_CLUB = {
  root: ROOTS_CLUB,
  list: path(ROOTS_CLUB, '/list'),
  new: path(ROOTS_CLUB, '/new'),
}

export const PATH_PRODUCTS = {
  root: ROOTS_PRODUCTS,
  rf: path(ROOTS_PRODUCTS, '/rf'),
  rv: path(ROOTS_PRODUCTS, '/rv'),
  offers: path(ROOTS_PRODUCTS, '/offers'),
  new: path(ROOTS_PRODUCTS, '/newcontent'),
  contents: path(ROOTS_PRODUCTS, '/contents'),
  content: path(ROOTS_PRODUCTS, '/content'),
  acessos: path(ROOTS_PRODUCTS, '/acessos'),
  fundos: path(ROOTS_PRODUCTS, '/fundos'),
  fiis: path(ROOTS_PRODUCTS, '/fiis'),
  prev: path(ROOTS_PRODUCTS, '/prev'),
  cambio: path(ROOTS_PRODUCTS, '/cambio'),
  internacionais: path(ROOTS_PRODUCTS, '/internacionais'),
  lumi: path(ROOTS_PRODUCTS, '/lumi'),
  alternativos: path(ROOTS_PRODUCTS, '/alternativos'),
  alocacao: path(ROOTS_PRODUCTS, '/alocacao')

};

export const PATH_S_RANKING_LEADER = {
  root: ROOTS_S_RANKING,
  general: path(ROOTS_S_RANKING_LEADER, '/general'),
  team: path(ROOTS_S_RANKING_LEADER, '/team')
};

export const PATH_OKR = {
  root: ROOTS_OKR,
  followUp: {
    root: path(ROOTS_OKR, '/follow-up'),
    general: path(ROOTS_OKR, '/follow-up/general'),
    advisor: path(ROOTS_OKR, '/follow-up/advisor'),
    leader: path(ROOTS_OKR, '/follow-up/leader'),
    gu: path(ROOTS_OKR, '/follow-up/gu'),
  },
  about: path(ROOTS_OKR, '/about'),
};

export const PATH_TOOLS = {
  root: ROOTS_TOOLS,
  find: path(ROOTS_TOOLS, '/find'),
  links: path(ROOTS_TOOLS, '/links'),
  scheduler: path(ROOTS_TOOLS, '/scheduler'),
  rooms: {
    root: path(ROOTS_TOOLS, '/rooms'),
    new: path(ROOTS_TOOLS, '/rooms/new'),
    list: path(ROOTS_TOOLS, '/rooms/list'),
  },
  gv: {
    root: path(ROOTS_TOOLS, '/gv'),
    fullscreen: path(ROOTS_TOOLS, '/gv/fullscreen'),
    playlists: path(ROOTS_TOOLS, '/gv/playlists'),
    control: path(ROOTS_TOOLS, '/gv/control'),
    monitoring: path(ROOTS_TOOLS, '/gv/monitoring'),
  },
  upload: {
    root: path(ROOTS_TOOLS, '/upload'),
    new: path(ROOTS_TOOLS, '/upload/new'),
    list: path(ROOTS_TOOLS, '/upload/list'),
    edit: (name) => path(ROOTS_TOOLS, `/upload/${name}/edit`),
  },
  sharing: {
    root: path(ROOTS_TOOLS, '/sharing'),
    list: path(ROOTS_TOOLS, '/sharing/list'),
    all: path(ROOTS_TOOLS, '/sharing/all'),
    new: path(ROOTS_TOOLS, '/sharing/new'),
    view: (name) => path(ROOTS_TOOLS, `/sharing/post/${name}`),
    edit: (name) => path(ROOTS_TOOLS, `/sharing/post/${name}/edit`),
  },
};

export const PATH_BACKOFFICE = {
  tickets: path(ROOTS_BACKOFFICE, '/tickets'),
  newTicket: path(ROOTS_BACKOFFICE, '/newticket'),
};

export const PATH_VCS_TICKETS = {
  tickets: path(ROOTS_VCS, '/tickets'),
  newTicket: path(ROOTS_VCS, '/newticket'),
};

export const PATH_TI_TICKETS = {
  tickets: path(ROOTS_TI, '/tickets'),
  newTicket: path(ROOTS_TI, '/newticket'),
};

export const PATH_EXPORT = {
  root: ROOTS_EXPORT,
  aai: path(ROOTS_EXPORT, '/aai'),
  clientes: path(ROOTS_EXPORT, '/basesclientes'),
};

export const PATH_NPS = {
  root: ROOTS_NPS,
  medallia: path(ROOTS_NPS, '/medallia'),
  medalliaAAI: path(ROOTS_NPS, '/medalliaaai')
};

export const PATH_REQUESTS = {
  root: ROOTS_REQUESTS,
  transfer: {
    root: path(ROOTS_REQUESTS, '/transfer'),
    new: path(ROOTS_REQUESTS, '/transfer/new'),
    history: path(ROOTS_REQUESTS, '/transfer/history'),
    followUp: path(ROOTS_REQUESTS, '/transfer/follow-up'),
    edit: (name) => path(ROOTS_REQUESTS, `/transfer/${name}/edit`),
  }
};

export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
  user: {
    root: path(ROOTS_SETTINGS, '/user'),
    list: path(ROOTS_SETTINGS, '/user/list'),
    mass: path(ROOTS_SETTINGS, '/user/mass'),
    newUser: path(ROOTS_SETTINGS, '/user/new'),
    updates: path(ROOTS_SETTINGS, '/user/updates'),
    cognito: path(ROOTS_SETTINGS, '/user/cognito-logs'),
  },
};

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  rankingsXP: path(ROOTS_REPORT, '/rankingsxp'),
  wealthVGR: path(ROOTS_REPORT, '/wealthvgr'),
  payback: path(ROOTS_REPORT, '/paybackaai'),
  destaquesaais: path(ROOTS_REPORT, '/destaquesaais'),
  newN3: path(ROOTS_REPORT, '/reunioes/newN3'),
  feeFixo: path(ROOTS_REPORT, '/performance/feefixo'),
  brokers: path(ROOTS_REPORT, '/brokers'),
  eventos: path(ROOTS_REPORT, '/events'),
  creditoXP: path(ROOTS_REPORT, '/creditoxp'),
  campanhas: {
    root: path(ROOTS_REPORT, '/campanhas'),
    ativacao300k: {
      ranking: path(ROOTS_REPORT, '/campanhas/ativacao300k/ranking'),
      consulta: path(ROOTS_REPORT, '/campanhas/ativacao300k/consulta'),
    },
  },
  performance: {
    root: path(ROOTS_REPORT, '/performance'),
    corretagem: {
      advisor: path(ROOTS_REPORT, '/performance/corretagem/advisor'),
    },
    gerencial: path(ROOTS_REPORT, '/performance/gerencial'),
    bases: path(ROOTS_REPORT, '/performance/bases'),
    cambio: path(ROOTS_REPORT, '/performance/cambio'),
    receitaGestor: path(ROOTS_REPORT, '/performance/receita/manager'),
    rating: path(ROOTS_REPORT, '/performance/rating'),
    ratingLeader: path(ROOTS_REPORT, '/performance/ratingleader'),
    ratingLeaderSelf: path(ROOTS_REPORT, '/performance/ratingleaderself'),
    ratingLeaderManager: path(ROOTS_REPORT, '/performance/ratingleadermanager'),
  },
  metas: {
    master: path(ROOTS_REPORT, '/metas/master'),
  },
  gerencialvcs: path(ROOTS_REPORT, '/gerencialvcs'),
  clientHistory: path(ROOTS_REPORT, '/clienthistory'),
  sdrleads: path(ROOTS_REPORT, '/sdrleads'),
  rankings: path(ROOTS_REPORT, '/rankingsgerais'),
  compromissadas: {
    root: path(ROOTS_REPORT, '/compromissadas'),
    gu: path(ROOTS_REPORT, '/compromissadas/gu')
  },
  mesaBanking: path(ROOTS_REPORT, '/mesabanking'),
  prevXP: path(ROOTS_REPORT, '/prevxp')
};

export const ATIVOMETRO = {
  rankingPJ: '/ativometro/pj/ranking',
  aaiPJ: '/ativometro/pj/aai',
  root: '/ativometro'
};

export const INTERNAL_TRANSFERS = {
  request: '/internaltransfers',
  follow: '/transferorders'
};

export const PATH_PIPE = {
  root: ROOTS_PIPE,
  pipeAdvisor: path(ROOTS_PIPE, '/advisor'),
  pipeLeader: path(ROOTS_PIPE, '/leader'),
  pipeManager: path(ROOTS_PIPE, '/manager'),
  pipeHead: path(ROOTS_PIPE, '/head'),
  fup: path(ROOTS_PIPE, '/fup'),
};

export const PATH_CAMPAIGNS = {
  root: ROOTS_CAMPAIGNS,
  ativacao300k: {
    ranking: path(ROOTS_CAMPAIGNS, '/ativacao300k/ranking'),
    consulta: path(ROOTS_CAMPAIGNS, '/ativacao300k/consulta'),
  },
};

export const PATH_RKYC = {
  root: ROOTS_RKYC,
  list: path(ROOTS_RKYC, '/list'),
  new: path(ROOTS_RKYC, '/form'),
}

export const PATH_DOCUMENTS = {
  root: ROOTS_DOCUMENTS,
  list: path(ROOTS_DOCUMENTS, '/list'),
  edit: path(ROOTS_DOCUMENTS, '/edit'),
}

export const PATH_EVENTS = {
  root: ROOTS_EVENTS,
  list: path(ROOTS_EVENTS, '/list'),
  new: path(ROOTS_EVENTS, '/new')
}

export const PATH_TRAINING = {
  root: ROOTS_TRAINING,
  list: path(ROOTS_TRAINING, '/list'),
  new: path(ROOTS_TRAINING, '/new'),
  newClass: path(ROOTS_TRAINING, '/class/new'),
  classes: path(ROOTS_TRAINING, '/classes'),
  class: path(ROOTS_TRAINING, '/class'),
  officer: path(ROOTS_TRAINING, '/officer'),
}

export const PATH_GU = {
  root: ROOTS_GU,
  bases: path(ROOTS_GU, '/bases'),
  book: path(ROOTS_GU, '/booktatico'),
  feeFixo: path(ROOTS_GU, '/feefixo'),
  acessos: path(ROOTS_GU, '/acessos'),
  campanhasCrm: path(ROOTS_GU, '/campanhascrm'),
  ativAsses: path(ROOTS_GU, '/ativacao300kconsultagu'),
  ativMaster: path(ROOTS_GU, '/ativacao300krankinggu'),
  gerencial: path(ROOTS_GU, '/gerencialgu'),
  oportunidades: path(ROOTS_GU, '/oportunidadesativacaogu'),
  previdencia: path(ROOTS_GU, '/previdenciagu'),
  superRanking: path(ROOTS_GU, '/superrankinggu'),
  superRankingLeader: path(ROOTS_GU, '/superrankinglideresgu'),
  indicadores: path(ROOTS_GU, '/indicadores'),
  n2: path(ROOTS_GU, '/n2'),
  okr: path(ROOTS_GU, '/okr'),
  guDigi: path(ROOTS_GU, '/gudigi'),
  receitaGestor: path(ROOTS_GU, '/receita'),
  cambio: path(ROOTS_GU, '/cambio'),
  metas: path(ROOTS_GU, '/metas'),
  // rankingsXP: path(ROOTS_GU, '/rankingsxp'),
  xsell: path(ROOTS_GU, '/xsell')
}

export const PATH_DOCS = 'mailto:controle@valorinvestimentos.com.br';